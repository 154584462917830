<style scoped>
.width-xs {
  width: 180px;
}

.content-head .ivu-form-item {
  margin-bottom: 10px;
}

.couponcss {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  display: flex;
  align-items: center;
  width: 210px;
}

.redtext {
  font-size: 20px;
  font-weight: 500;
  color: #d0251a;
}

.couponremark {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.couponrule {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: #222222;
  word-break: keep-all;
}

.coupontime {
  word-break: keep-all;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}

.couponuse {
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 5%;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  word-break: keep-all;
}
</style>
<template>
  <div class="page-main">
    <div class="page-content content-main">
      <div class="content-head">
        <p class="title">消息明细（共{{ pager.total }}条数据）</p>
        <div style="padding-top: 34px">
          <Form
            class="custom-form"
            :model="formData"
            label-position="right"
            :label-width="95"
            inline
          >
            <Row>
              <FormItem label="消息编号">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.pfMessageConfigCode"
                  clearable
                />
              </FormItem>
              <FormItem label="推送渠道">
                <Select
                  v-model="formData.channelType"
                  clearable
                  class="width-xs"
                >
                  <Option
                    v-for="(item, key) in MESSAGE_CHANNEL_TYPE"
                    :value="item.value"
                    :key="key"
                  >
                    {{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="消息类型">
                <Select
                  v-model="formData.businessType"
                  clearable
                  class="width-xs"
                >
                  <Option
                    v-for="(item, key) in businessTypeList"
                    :value="item.value"
                    :key="key"
                  >
                    {{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="会员编码">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.recipientNo"
                  clearable
                />
              </FormItem>
            </Row>
            <Row style="justify-content: flex-end">
              <Button type="primary" @click="queryData" style="margin-left: 5px"
                >查询</Button
              >
              <Button
                type="primary"
                @click="clearFormData"
                style="margin-left: 5px"
                >重置</Button
              >
            </Row>
          </Form>
        </div>
      </div>
      <div class="page-content-item">
        <sp-table
          :columns="columns"
          :data="dataList"
          :loading="loading"
          :show-total="false"
          :total="pager.total"
          :page="pager.pageNum"
          :page-size="pager.pageSize"
          @change="changePage"
        >
        </sp-table>
      </div>
      <Modal title="消息内容" v-model="logDialogDisplay" width="800">
        <div class="page-content-item">
          <h3>{{ loglist.title }}</h3>
          <div id="logList_Message"></div>
        </div>
        <div slot="footer">
          <Button type="text" size="large" @click="logDialogDisplay = false"
            >取消</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
  import {
    MESSAGE_CHANNEL_TYPE,
  } from '@/util/enum';
  export default {
    data() {
      return {
        businessTypeList: [],
        MESSAGE_CHANNEL_TYPE,
        formData: {
          // 查询数据
          recipientNo: '',
          businessType: '',
          channelType: 'INNM',
          pfMessageConfigCode: '',
        },
        formParams: {}, // 提交查询的参数，避免填写了表单数据但并未提交查询时点击翻页按钮导致误把查询条件带进去

        columns: [
          {
            title: '消息编号',
            key: 'pfModelCode',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '消息类型',
            key: 'businessType',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              let content = this.businessTypeList.filter(
                (v) => v.value === row.businessType
              );
              return h('div', content[0] && content[0].label);
            },
          },
          {
            title: '消息标题',
            key: 'title',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '会员编码',
            key: 'recipientNo',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '推送渠道',
            key: 'channelType',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              let type = row.channelType; // this.formData.channelType;
              let content = MESSAGE_CHANNEL_TYPE.filter((v) => v.value === type);
              return h('div', content[0] && content[0].label);
            },
          },
          {
            title: '发送时间',
            key: 'sendTime',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '是否已读',
            key: 'isRead',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              return h('div', ['未读', '已读'][row.isRead]);
            },
          },

          {
            title: '消息内容',
            width: 200,
            align: 'center',
            render: (h, { row }) => {
              let buttons = [];
              let btnList = {
                detail: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.detailData(row);
                      },
                    },
                  },
                  '查看'
                ),
              };
              buttons.push(btnList.detail);
              return h('div', buttons);
            },
          },
        ],
        dataList: [],
        loading: false, // 表格查询状态
        pager: {
          total: 0, // 总条数
          pageNum: 1, // 当前页码
          pageSize: 10, // 每页条数
        },
        loglist: {
          title: '',
        },
        logDialogDisplay: false,
        logLoading: false,
        logCol: [
          {
            title: '业务类型',
            key: 'type',
            align: 'center',
          },
          {
            title: '失败原因',
            key: 'desc',
            align: 'center',
          },
        ],
      };
    },
    created() {
      this.queryData();
      this.queryBusinessTypeList();
    },
    methods: {
      /**
       * 获取消息类型
       */
      queryBusinessTypeList() {
        let data = {
          subType: '',
          type: 'busniess_type',
        };
        this.$axios({
          url: this.$api.message.queryBusinessTypeList,
          data,
        }).then((data) => {
          this.businessTypeList = data || [];
        });
      },

      /**
       * 获取数据
       */
      getData() {
        // 默认类型是站内信
        this.formParams.pageNum = this.pager.pageNum;
        this.formParams.pageSize = this.pager.pageSize;
        this.loading = true;
        this.$axios({
          url: this.$api.message.getMessageList,
          data: this.formParams,
          complete: () => {
            this.loading = false;
          }
        }).then((data) => {
          this.dataList = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.formParams = Object.assign({}, this.formData);
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      },
      /**
       * 重置查询条件
       */
      clearFormData() {
        let tmpformData = {
          recipientNo: '',
          businessType: '',
          channelType: 'INNM',
          pfMessageConfigCode: '',
        };
        this.formData = tmpformData;
      },
      detailData(row) {
        document.getElementById('logList_Message').innerHTML = row.content;
        this.loglist.title = row.title;
        this.logDialogDisplay = true;
      },
    },
  };
</script>
